module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_babel-plugin-styled-components@2.1.4_@babel+core_44ab3515c735a14343f170648912b06d/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-do_8f669d38e34add79f46b1d1b5b0e16d7/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Afronaut Ventures","short_name":"Afronaut Ventures","description":"We provide technical and business development for African startups.","start_url":"/","lang":"en","background_color":"#18191b","theme_color":"#18191b","display":"minimal-ui","icon":"static/brand/favicon-1.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d67378feedaa18e7a19c7af24a03f4c1"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-theme-i18n@3.0.0_gatsby-plugin-react-helmet@6.14.0_gatsby@5.14.1_babel-eslint@10_eefa054aeea44759059aa20052dd2f38/node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","prefixDefault":true,"configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@1_62eb9432d40ee35e7464741c4fc8e12e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
